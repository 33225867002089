import { Card, Table } from "@darktrace/ui-components";
import React, { useState } from "react";
import styled from "styled-components";
import { useClientApplicationsQuery, useMultiInstanceProductScopesQuery } from "../../logic/api.js";
import { useActiveClientId } from "../../logic/hooks.js";
import { ClientApplicationsSidepanel } from "./ClientApplicationsSidepanel.jsx";
import { Scopes } from "./Scopes.jsx";
import { ClientApplicationsOptionsButton } from "./ClientApplicationsOptionsButton.jsx";

const StyledClientApplications = styled.div`
  height: 100%;

  display: flex;
  gap: 2rem;

  .integrations-panel {
    flex-grow: 1;

    > .dt-ui-card__contents {
      padding: 0;
    }

    .product-column {
      width: 24rem;
      white-space: nowrap;
    }
  }
`;

export function ClientApplications() {
  const activeClientId = useActiveClientId();

  const [selectedApplicationId, setSelectedApplicationId] = useState();

  const { data: clientApplications = [] } = useClientApplicationsQuery({ clientId: activeClientId });
  const { data: mulitInstanceProductScopes = [] } = useMultiInstanceProductScopesQuery({
    clientId: activeClientId,
    enabled: !!activeClientId,
  });

  const selectedApplication = clientApplications.find((application) => application.id === selectedApplicationId);

  const rowData = mulitInstanceProductScopes
    ? mulitInstanceProductScopes?.map(({ instanceId, instanceName, groups }) => ({
        rowId: instanceId,
        data: [
          instanceName,
          <Scopes
            key={`${instanceId}_scope`}
            groups={groups}
            instanceName={instanceName}
            instanceId={instanceId}
            selectedApplication={selectedApplication}
            activeClientId={activeClientId}
          />,
        ],
      }))
    : [{}];

  return (
    <StyledClientApplications>
      <ClientApplicationsSidepanel
        selectedApplicationId={selectedApplicationId}
        setSelectedApplicationId={setSelectedApplicationId}
        selectedApplication={selectedApplication}
      />

      {selectedApplicationId && (
        <Card
          className="integrations-panel"
          titleCenter={t(`Application Scopes`)}
          titleRight={
            <ClientApplicationsOptionsButton application={selectedApplication} setSelectedApplicationId={setSelectedApplicationId} />
          }
        >
          <Table
            headers={[
              { id: "application", title: t(`Product`), className: "product-column" },
              { id: "scope", title: t(`Scopes`) },
            ]}
            rows={rowData}
            style={{ width: "100%" }}
          />
        </Card>
      )}
    </StyledClientApplications>
  );
}
