import React, { useRef, useState } from "react";
import { useActiveClientId, useIsInView } from "../../logic/hooks.js";
import { useProductAccessInstances, useUserProductAccessInstancesStream } from "../../logic/api.js";
import { GhostElement, IconButton, Pill, Tooltip } from "@darktrace/ui-components";
import styled from "styled-components";
import { AssignProductsModal } from "./AssignProductsModal.jsx";

const StyledGhostPill = styled(Pill)`
  min-width: 6rem;
`;

const StyledProductAccess = styled.div`
  .plus-btn {
    flex-shrink: 0;
  }

  .product-access-pills {
    display: flex;
    gap: 0.4rem;
    flex-wrap: wrap;
  }
`;

export function ProductAccess({ userId, name, email, clientId: userClientId, isYou }) {
  const modalRoot = document.querySelector("#modal-root");
  const cellRef = useRef();
  const element = cellRef?.current?.closest("tr");
  const scrollContainer = document.querySelector(".dt-ui-table__wrapper");
  const isInView = useIsInView({ element, scrollContainer, boundaryElement: scrollContainer });
  const activeClientId = useActiveClientId();

  const [isAssignProductsModalOpen, setIsAssignProductsModalOpen] = useState(false);

  const { data: clientProductAccessInstances = [] } = useProductAccessInstances({ clientId: activeClientId });
  const { data: productAccessInstances, isFetching } = useUserProductAccessInstancesStream({
    userId,
    key: `user-management-product-access-${userId}`,
    clientId: activeClientId,
    enabled: isInView,
  });

  const uniqueProducts = productAccessInstances.reduce((acc, current) => {
    const isCustomerPortal = current.serviceType === "customer-portal-service-type";
    if (isCustomerPortal && activeClientId !== userClientId) return acc;

    if (acc.some((product) => product.serviceType === current.serviceType)) return acc;
    else acc.push({ ...current, type: clientProductAccessInstances.find((instance) => instance.instanceId === current.instanceId)?.type });

    return acc;
  }, []);

  return (
    <StyledProductAccess ref={cellRef} key="product-access" className="product-access">
      <Tooltip text="Assign Products to User">
        <IconButton
          className="plus-btn"
          size="small"
          icon="plus"
          variant="secondary"
          onClick={() => setIsAssignProductsModalOpen((prev) => !prev)}
        />
      </Tooltip>

      <AssignProductsModal
        open={isAssignProductsModalOpen}
        onClose={() => setIsAssignProductsModalOpen(false)}
        user={{ id: userId, name, email, clientId: userClientId }}
        isYou={isYou}
      />

      <div className="product-access-pills">
        {uniqueProducts.map(({ type, serviceName, instanceName, instanceId }, index) => {
          const productName = serviceName ?? instanceName;
          const isSabre = type === "sabre-threat-visualiser-product-access";

          return (
            <ProductAccessPill
              key={index}
              modalRoot={modalRoot}
              productName={productName}
              instanceId={instanceId}
              userId={userId}
              name={name}
              email={email}
              clientId={userClientId}
              isYou={isYou}
              isSabre={isSabre}
            />
          );
        })}
        {isFetching && (
          <GhostElement>
            <StyledGhostPill />
          </GhostElement>
        )}
      </div>
    </StyledProductAccess>
  );
}

function ProductAccessPill({ productName, instanceId, userId, name, email, clientId, isYou }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Pill actionIcon="pen" onClick={() => setIsModalOpen(true)} onActionClick={() => setIsModalOpen(true)}>
        {productName}
      </Pill>

      <AssignProductsModal
        instanceIdToHighlight={instanceId}
        isYou={isYou}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        user={{ id: userId, name, email, clientId }}
      />
    </>
  );
}
